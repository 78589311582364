<template>
  <div class="v-wrapper tool--none">
    <div class="v-body">
      <template>
        <div class="search-bars">

          <span class="item-label">{{$t('userLog.range')}}:</span>
          <el-date-picker
            v-model="timeRange"
            class="etime"
            size="small"
            type="datetimerange"
            range-sparator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>

          <search-group :field="field" style="float:right;" :options="options" @click="click" @input="({select,value,Op}) => {searchName=select;searchText=value,searchOption=Op}"></search-group>
        </div>

        <el-table :data="tableData" class="v-table" border @header-dragend="saveHeaderDragWidth" :height="tableHeight" ref="multipleTable" size="mini" v-loading="tableLoading" :element-loading-text="tableLoadingText" @sort-change="sortChange">
          <el-table-column prop="LogTime" :label="$t('userLog.operateTime')" :width="TW($t('userLog.operateTime'), 170)" sortable show-overflow-tooltip>
            <p slot-scope="{row}">{{miment(row.LogTime).format()}}</p>
          </el-table-column>
          <el-table-column :label="$t('userLog.user')" :width="TW($t('userLog.user'), 150)">
            <template slot-scope="{row}">
              <span class="primaryText">{{row.Name}}</span> /
              <span class="successText">{{row.UserName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="OperateType" :label="$t('userLog.operateType')" :width="TW($t('userLog.operateType'), 120)">
            <span slot-scope="{row}">{{$t('userLog')[row.OperateType] || row.OperateType}}</span>
          </el-table-column>
          <el-table-column prop="UserLog" :label="$t('userLog.description')" :width="TW($t('userLog.description'),null)" ></el-table-column>
          <el-table-column prop="IP" :label="$t('userLog.remark')" :width="TW($t('userLog.remark'),150)" >
            <span slot-scope="{row}">{{$t('userLog')[row.IP] || row.IP}}</span>
          </el-table-column>
        </el-table>

        <el-pagination
          class="v-pager"
          :page-size="pageSize"
          :page-sizes="pageSizes"
          :pager-count="11"
          :current-page="pageIndex"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotalCount"
          background
          @current-change="pageCurrentChange"
          @size-change="pageSizeChange"
          popper-class="custom">
        </el-pagination>

        <vcl-code v-if="false" class="ima-4" :width="400" :height="150"></vcl-code>

        <transition name="scale-grow" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
          <router-view @refresh="refreshHandle"></router-view>
        </transition>

        <!-- <div class="page__detail">
          测试页面
        </div> -->
      </template>
    </div>
  </div>
</template>

<script>
import mixin from '@/views/manage/mixin'
import { Query } from '@/api/log'
import {Filter, Op} from '@/api/config'
import miment from 'miment'

export default {
  name: 'log',
  data() {
    return {
      miment,
      options: [
        { label: '用户名称', value: 'H.Name', Op: Op.Contains },
        { label: '账号', value: 'U.UserName', Op: Op.Contains },
        { label: '操作类型', value: 'L.OperateType', Op: Op.Contains },
        { label: '操作描述', value: 'L.UserLog', Op: Op.Contains }
      ],
      field: {
        select: 'L.UserLog',
        value: ''
      },
      riskListOptions: [],
      startTime: miment().add(-3, "DD"),
      endTime: miment().add(1, "DD"),
      timeRange: [miment().add(-3, "DD"), miment().add(1, "DD")],
      isAnimating: false // 是否正在动画
    }
  },
  mixins: [
    mixin
  ],
  watch : {
    '$i18n.locale': 'getOptions'
  },
  created() {
    this.getOptions()
    this.pageIndex = 1

    this.queryHandle()
  },
  methods: {
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    getOptions() {
      this.options = [
        { label: this.$t('userLog.userName'), value: 'H.Name', Op: Op.Contains },
        { label: this.$t('userLog.account'), value: 'U.UserName', Op: Op.Contains },
        { label: this.$t('userLog.operateType'), value: 'L.OperateType', Op: Op.Contains },
        { label: this.$t('userLog.description'), value: 'L.UserLog', Op: Op.Contains }
      ]
    },
    queryHandle() {
      const that = this;
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))

        this.tableLoading = true
        Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.timeRange[0], this.timeRange[1]).then(ret => {
          this.tableData = ret.data.list.slice()
          this.tableData.forEach(element => {
            if(element.OperateType==='新增'){
              element.UserLog = element.UserLog.split(' ')
              element.UserLog[1]= ' -'+ that.$t('userLog.order1')+'- '
            }
          });
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    refreshHandle() {
      this.pageIndex = 1
      this.queryHandle()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
.v-table {
  width: 100%;
}
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 8px;
  .etime{
    border-radius: 20px;
  }
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
  }
}
.riskitem {
  color: #f44336;
  display: block;
  width: 80px;
}

// @mixin customLine {
//   .el-input__inner, & ::v-deep .el-input__inner {
//     border-radius: 2px;
//     border-color: rgba($home-colorprimary, .4);
//     background-color: inherit;
//     & ::v-deep .el-range-input {
//       background-color: inherit;
//     }
//     &.is-active, &:focus {
//       border-color: rgba($home-colorprimary, 1);
//     }
//   }
// }

// .search-bars {
//   padding: 12px 24px;
//   margin-bottom: 8px;
//   //background-color: $home-backcolor;
//   .item-label {
//     color: $--color-text-regular;
//     font-size: 14px;
//     margin-right: 1em;
//   }
//   @include customLine;
//   .custom-input-group {
//     @include customLine;
//   }
// }

// .v-table {
//   font-size: 13px;
//   width: calc(100% - 48px);
//   margin-left: 24px;
//   & ::v-deep {
//     .el-table__header {
//       tr > th {
//         border-bottom: none;
//         background-color: #1E88E5;
//         color: #F5F5F5;
//         font-weight: 500;
//         &:first-child .cell {
//           padding-left: 24px;
//         }
//         &:last-child .cell {
//           padding-right: 24px;
//         }
//       }
//     }
//     .el-table__body {
//       tr > td {
//         border-bottom: none;
//         &:first-child .cell {
//           padding-left: 24px;
//         }
//         &:last-child .cell {
//           padding-right: 24px;
//         }
//       }
//       tr:nth-child(2n) > td {
//         background-color: $home-backcolor;
//       }
//       tr:nth-child(2n):hover td {
//         background-color: $home-backcolor;
//       }
//       tr:nth-child(2n+1):hover td {
//         background-color: inherit;
//       }
//     }
//   }
// }
// .v-pager {
//   padding: 12px 24px;
//   margin-top: 8px;
//   //background-color: $home-backcolor;
//   text-align: center;
//   @include customLine;
//   & ::v-deep .el-pagination__sizes .el-input__inner{
//     border: 0;
//   }
//   & ::v-deep .el-pagination__jump {
//     float: right;
//   }
//   & ::v-deep {
//     .btn-prev,.btn-next {
//       background-color: inherit;
//     }
//     .el-pager li {
//       background-color: inherit;
//     }
//   }
// }
</style>
